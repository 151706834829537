.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
    border-radius: 5px
}

.react-daterange-picker__wrapper {
    padding: 6px;
    border: thin solid var(--chakra-colors-gray-200)
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1em 1em
}